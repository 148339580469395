.sendbird-channel-preview
	.sendbird-channel-preview__content
	.sendbird-channel-preview__content__upper__header__total-members {
	display: none;
}

.sendbird-user-message__avatar {
	display: none;
}
.sendbird-channel-header .sendbird-channel-header__right-icon {
	display: none;
}
.sendbird-chat-header__right {
	display: none;
}
.sendbird-message-input path,
.sendbird-place-holder path {
	fill: #d6a76f;
}
.sendbird-channel-preview .sendbird-channel-preview__avatar {
	display: none;
}
.sendbird-conversation__messages .sendbird-separator {
	display: none;
}
.sendbird-avatar {
	display: none;
}
.sendbird-chat-header .sendbird-chat-header__subtitle {
	display: none;
}

.sendbird-theme--light
	.sendbird-channel-preview--active
	.sendbird-channel-preview__content__upper__header__channel-name {
	color: black;
}
.sendbird-theme--light .sendbird-conversation {
	background-color: #f5f5f5;
}
/*  hide channel list header for now , deisgn need to render name with active time */
.sendbird-channel-list__header {
	display: none;
}
.sendbird-channel-header {
	padding: 0;
}
.sendbird-channel-preview:hover .sendbird-channel-preview__action .sendbird-iconbutton {
	display: none;
}
/* .sendbird-channel-preview
	.sendbird-channel-preview__content
	.sendbird-channel-preview__content__upper__last-message-at {
	color: black;
} */
